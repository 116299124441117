@tailwind base;
@tailwind components;
@tailwind utilities;    


@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/SourceSansPro-Light.ttf') format('woff2');
  }
*{

    margin: 0;
    padding: 0;
    box-sizing:border-box;
}
body {
    margin: 0;
    font-family:'Source Sans Pro', -apple-system, BlinkMacSystemFont,'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: hsl(0, 0%, 98%);
    scroll-behavior: smooth;
  }
  

svg{
    display: flex;
    align-items: center;
}
